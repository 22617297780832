import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Keyframes, animated, Transition } from 'react-spring';
import PropTypes from 'prop-types';
import { setNavVisible, setTriggerVisible } from '../actions/navActions';
import Facebook from '../svg/facebook.svg';
import Linkedin from '../svg/linkedin.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const NavEffect = Keyframes.Spring({
  open: { transform: 'translateX(0%)', visibility: 'visible', delay: 200 },
  close: [{ transform: 'translateX(100%)' }, { visibility: 'hidden' }],
});

class Navigation extends Component {
  toggle = () => this.props.setNavVisible(!this.props.nav.navIsVisible);

  render() {
    const effect = this.props.nav.navIsVisible ? 'open' : 'close';
    const { triggerIsVisible } = this.props.nav;
    return (
      <section>
        <Transition
          native={true}
          items={triggerIsVisible}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {triggerIsVisible => triggerIsVisible && (style => (
            <animated.div className="Navigation__fixed" style={style}>
              <h1>Gravures Techniques<br />& Industrielles</h1>
              <div className={classNames('Navigation__trigger', { Navigation__trigger__cross: this.props.nav.navIsVisible })} onClick={() => this.toggle()}>
                <div className="Navigation__hamburger" />
              </div>
            </animated.div>
          ))}
        </Transition>
        <NavEffect native={true} state={effect}>
          {style => (
            <animated.nav className="Navigation" style={style}>
              <ul className="Navigation__list">
                <li>
                  <AniLink cover to="/" bg="#007A9B">
                    Home
                  </AniLink>
                </li>
                <li>
                  <AniLink cover to="/a-propos" bg="#C06C84">
                    À propos
                  </AniLink>
                </li>
                <li>
                  <AniLink cover to="/contact" bg="#F67280">
                    Contact
                  </AniLink>
                </li>
              </ul>
              <div>
                <ul className="Navigation__contact">
                  <li>Rue Saint Martin, 10</li>
                  <li className="mb-1">1457 Nil-Saint-Vincent</li>
                  <li className="mb-1">Tél: +32 10 65 73 87</li>
                  <li className="mb-1">Fax: +32 10 65 73 87</li>
                  <li className="mb-1">GSM: +32 473 53 22 19</li>
                  <li className="mb-1">GSM: +32 474 71 16 94</li>
                  <li className="mb-1">BE0834388951</li>
                  <li>
                    <Facebook />
                    <Linkedin />
                  </li>
                </ul>
              </div>
            </animated.nav>
          )}
        </NavEffect>
      </section>
    );
  }
}

Navigation.propTypes = {
  setTriggerVisible: PropTypes.func.isRequired,
  setNavVisible: PropTypes.func.isRequired,

  show: PropTypes.bool.isRequired,
  nav: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  show: state.animation.show,
  nav: state.nav,
});

const mapDispatchToProps = {
  setTriggerVisible,
  setNavVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
