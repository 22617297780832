import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import '../scss/app.scss';
import { setNavVisible } from '../actions/navActions';
import Nav from './Navigation';
import { connect } from 'react-redux';

class Layout extends Component {
  componentWillUnmount() {
    if (this.props.nav.navIsVisible) {
      this.props.setNavVisible(false);
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
          <section>
            <Nav />
            <main role="main">
              {this.props.children}
            </main>
          </section>
        )}
      />
    );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,

  setNavVisible: PropTypes.func.isRequired,

  nav: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  nav: state.nav,
});

const mapDispatchToProps = {
  setNavVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
