import { SET_NAV_VISIBLE, SET_TRIGGER_VISIBLE } from './types';

export const setTriggerVisible = bool => dispatch => {
  dispatch({
    type: SET_TRIGGER_VISIBLE,
    payload: bool,
  });
};

export const setNavVisible = bool => dispatch => {
  dispatch({
    type: SET_NAV_VISIBLE,
    payload: bool,
  });
};

